import React from 'react'

/**
 * Takes a string as an argument and converts its
 * linebreak characters (\n) to <br /> elements
 **/
const WithHtmlLinebreaks = ({ text }) => (
  <>
    {text.split('\n').map((line, i) => (
      <span key={i}>
        {line}
        <br />
      </span>
    ))}
  </>
)

export default WithHtmlLinebreaks
