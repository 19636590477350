// extracted by mini-css-extract-plugin
export var wrapper = "style-module--wrapper--wTb7s";
export var blueBg = "style-module--blueBg--DQ8UA";
export var perks = "style-module--perks--RGdKa";
export var textContainer = "style-module--textContainer----3vl";
export var perkItem = "style-module--perkItem--IQMyN";
export var even = "style-module--even--DsILh";
export var bottomImg = "style-module--bottomImg--bwz4i";
export var odd = "style-module--odd--HLKI8";
export var topImg = "style-module--topImg--qgRV1";
export var mobileImg = "style-module--mobileImg--h3evV";
export var buttonContainer = "style-module--buttonContainer--w9WWx";
export var mobileButton = "style-module--mobileButton--3dvd9";
export var desktopButton = "style-module--desktopButton--1pbDj";