// extracted by mini-css-extract-plugin
export var wrapper = "style-module--wrapper--apIu-";
export var leftColumn = "style-module--leftColumn--5v3vX";
export var title = "style-module--title--PXSIV";
export var subTitle = "style-module--subTitle--XRjXy";
export var link = "style-module--link--Rgdgl";
export var linkText = "style-module--linkText--lJkxW";
export var testimonies = "style-module--testimonies--AT8Lh";
export var testimony = "style-module--testimony--3BMs9";
export var apostrophes = "style-module--apostrophes--eEv3p";
export var contentWrapper = "style-module--contentWrapper--I04ys";
export var textWrapper = "style-module--textWrapper--6wXIh";
export var content = "style-module--content--vytNc";
export var name = "style-module--name--ksUwE";
export var roleAndCompany = "style-module--roleAndCompany--VA0zl";
export var imgWrapper = "style-module--imgWrapper--rsrOx";