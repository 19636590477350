import React from 'react'
import ReactMarkdown from 'react-markdown'
import ContentWrapper from '../ContentWrapper'
import * as s from './style.module.sass'

const FreelancePartnerships = ({ title, text, partnerships }) => (
  <div className={s.container}>
    <ContentWrapper>
      <h1>
        <ReactMarkdown>{title}</ReactMarkdown>
      </h1>
      <p className={s.text}>{text}</p>
      <div className={s.partnerships}>
        {partnerships.map((p, i) => (
          <div className={s.partnershipItem} key={i}>
            <div className={s.type}>{p.type}</div>
            <div className={s.offer}>{p.offer}</div>
            {/* {p.logo !== null && (
              <div className={s.logo}>
                <img src={p.logo.publicURL} alt={p.type} />
              </div>
            )} */}
          </div>
        ))}
      </div>
    </ContentWrapper>
  </div>
)

export default FreelancePartnerships
