/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React, { useState, useRef, useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import ContentWrapper from '../ContentWrapper'
import * as s from './style.module.sass'
import WithHtmlLinebreaks from '../WithHtmlLinebreaks'

// Calculate margin for image to be centered in container
// If image is larger than container, it will be cropped
// with equal overflow on each side
function calcMargin(containerRef, image) {
  if (containerRef.current !== null) {
    const { aspectRatio } = image.childImageSharp.fluid
    const { clientWidth, clientHeight } = containerRef.current

    const containerRatio = clientWidth / clientHeight
    const newWidth = clientHeight * aspectRatio

    if (containerRatio < aspectRatio) {
      return -(newWidth - clientWidth) / 2
    }
  }
  return 0
}

const Sectors = () => {
  const data = useStaticQuery(graphql`
    {
      markdownRemark(frontmatter: { componentKey: { eq: "sectors" } }) {
        frontmatter {
          title
          sectors {
            name
            icon {
              publicURL
            }
            percentage
            nbFreelances
            image {
              publicURL
              childImageSharp {
                fluid {
                  presentationWidth
                  presentationHeight
                  aspectRatio
                }
              }
            }
          }
          bottomTextTitle
          bottomTextContent
        }
      }
    }
  `)
  const { title, sectors, bottomTextTitle, bottomTextContent } = data.markdownRemark.frontmatter
  const [currentSector, setCurrentSector] = useState(null)
  const imgContainerRef = useRef(null)

  useEffect(() => setCurrentSector(sectors[0]), [sectors])

  return (
    <div className={s.sectorsSection}>
      <ContentWrapper>
        <h1>
          <WithHtmlLinebreaks text={title} />
        </h1>
        <div className={s.sectors}>
          <div className={s.sectorImage}>
            <div className={s.sectorImageContainer} ref={imgContainerRef}>
              {sectors.map((item, i) => {
                const { name, image } = item
                return (
                  !!image && <img
                    key={`picture_${i}`}
                    src={image.publicURL}
                    alt={name}
                    style={{
                      opacity: currentSector === item ? 1 : 0,
                      marginLeft: calcMargin(imgContainerRef, image)
                    }}
                  />
                )
              })}
            </div>
          </div>
          <ul className={s.sectorsList}>
            {sectors.map((item, i) => {
              const { name, icon, percentage } = item

              return (
                <li
                  key={i}
                  className={currentSector === item ? s.active : ''}
                  onClick={() => setCurrentSector(item)}
                >
                  {!!icon && <img src={icon.publicURL} alt={`icone ${name}`} width='22' height='22' />}
                  <span className={s.sectorName}>{name}</span>
                  <div className={s.rightPart}>
                    <div className={s.percentageContainer}>{percentage} %</div>
                    {/* <div className={s.nbFreelancesContainer}>
                      <Icon icon="person" iconSize="xsmall" containerSize="xsmall" />
                      <span className={s.nbFreelances}>{nbFreelances}</span>
                    </div> */}
                  </div>
                  <div className={s.borders} />
                </li>
              )
            })}
          </ul>
        </div>
        <div className={s.bottomText}>
          <h2>{bottomTextTitle}</h2>
          <p>
            <WithHtmlLinebreaks text={bottomTextContent} />
          </p>
        </div>
      </ContentWrapper>
    </div>
  )
}

export default Sectors
