// extracted by mini-css-extract-plugin
export var sectorsSection = "style-module--sectorsSection--0b6tD";
export var sectors = "style-module--sectors---DLd5";
export var sectorImage = "style-module--sectorImage--TkpEp";
export var sectorImageContainer = "style-module--sectorImageContainer--GNZtv";
export var sectorName = "style-module--sectorName--OubqK";
export var sectorsList = "style-module--sectorsList--OBLIq";
export var active = "style-module--active--XBGrs";
export var borders = "style-module--borders--dxsm4";
export var rightPart = "style-module--rightPart--fKTId";
export var nbFreelancesContainer = "style-module--nbFreelancesContainer--mqhqs";
export var percentageContainer = "style-module--percentageContainer--45qnc";
export var nbFreelances = "style-module--nbFreelances--COU09";
export var bottomText = "style-module--bottomText--K2OYs";