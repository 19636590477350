import React from 'react'
import ContentWrapper from '../ContentWrapper'
import WithHtmlLinebreaks from '../WithHtmlLinebreaks'
import Partners from '../Partners'
import * as s from './style.module.sass'

const ClientsPolicy = ({ text }) => (
  <ContentWrapper className={s.clientsPolicy}>
    <div className={s.blueText}>
      <h2>
        <WithHtmlLinebreaks text={text} />
      </h2>
    </div>
    <div className={s.partnersContainer}>
      <Partners inlineMode />
    </div>
  </ContentWrapper>
)

export default ClientsPolicy
