import React from 'react'
import { Link } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import ContentWrapper from '../ContentWrapper'
import Button from '../Button'
import WithHtmlLinebreaks from '../WithHtmlLinebreaks'
import * as s from './style.module.sass'

const FreelancePerks = ({ title, perks }) => (
  <div className={s.wrapper}>
    <div className={s.blueBg} />
    <ContentWrapper>
      <h1><ReactMarkdown>{title}</ReactMarkdown></h1>
      <div className={s.perks}>
        {perks.map((p, i) => (
          <div className={`${s.perkItem} ${i % 2 === 0 ? s.even : s.odd}`} key={i}>
            <img className={s.topImg} src={p.image.publicURL} alt={p.title} />
            <div className={s.textContainer}>
              <h3>{p.title}</h3>
              <p>
                <WithHtmlLinebreaks text={p.text} />
              </p>
              <img className={s.mobileImg} src={p.image.publicURL} alt={p.title} />
            </div>
            <img className={s.bottomImg} src={p.image.publicURL} alt={p.title} />
          </div>
        ))}
      </div>
      <div className={s.buttonContainer}>
        <div className={s.mobileButton}>
          <Link to="/comment-ca-marche">
            <Button>Découvrir notre fonctionnement en détail</Button>
          </Link>
        </div>
        <div className={s.desktopButton}>
          <Link to="/comment-ca-marche">
            <Button icon="rightArrow">Découvrir notre fonctionnement en détail</Button>
          </Link>
        </div>
      </div>
    </ContentWrapper>
  </div>
)

export default FreelancePerks
