// extracted by mini-css-extract-plugin
export var hero = "style-module--hero--oVF1E";
export var freelance = "style-module--freelance--aPSZU";
export var mainTitle = "style-module--mainTitle--TuI73";
export var picContainer = "style-module--picContainer--o+wLf";
export var gatsbyImageWrapper = "style-module--gatsbyImageWrapper--G8zwn";
export var contentContainer = "style-module--contentContainer--ZUFYl";
export var heroContent = "style-module--heroContent--Zc4GH";
export var formContainer = "style-module--formContainer--K4b9O";
export var ctaContainer = "style-module--ctaContainer--hRHFH";
export var subTitle = "style-module--subTitle--JRqEJ";
export var artelinkMissionProposal = "style-module--artelinkMissionProposal--qSvUR";
export var iconContainer = "style-module--iconContainer--D1RKz";
export var bottomText = "style-module--bottomText--4wStF";