import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import ReactMarkdown from 'react-markdown'

import * as s from './style.module.sass'

import ContentWrapper from '../ContentWrapper'
import SearchForm from '../SearchForm'
import Button from '../Button'

const Hero = ({
  title,
  subTitle,
  image,
  className = '',
  cta = 'Je candidate',
  ctaLink = `${process.env.GATSBY_APP_URL}/signup?pType=FREELANCE`,
  withSearchBar = false
}) => (
  <div className={`${s.hero} ${s[className]}`}>
    <div className={s.picContainer}>
      <GatsbyImage image={image.childImageSharp.gatsbyImageData} className={s.gatsbyImageWrapper} alt="hero cover"/>
    </div>
    <ContentWrapper className={s.contentContainer}>
      <div className={s.heroContent}>
        <div className={s.mainTitle}>
          <h1>
            <ReactMarkdown>{title}</ReactMarkdown>
          </h1>
        </div>
        <p className={s.subTitle}>{subTitle}</p>
        {
          withSearchBar ? (
            <div className={s.formContainer}>
              <SearchForm eventName='search' />
            </div>
          ) : (
            <div className={s.ctaContainer}>
              <a href={ctaLink}>
                <Button icon="rightArrow">{cta}</Button>
              </a>
            </div>
          )
        }
        <p className={s.artelinkMissionProposal}>Besoin d'être accompagné dans votre recherche ?<Link to='/proposition-mission/'>Décrivez-nous votre besoin !</Link></p>
      </div>
    </ContentWrapper>
  </div>
)

export default Hero
