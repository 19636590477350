import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { Helmet } from 'react-helmet'

import Layout from '../components/Layout'
import Hero from '../components/Hero'
import ClientsPolicy from '../components/ClientsPolicy'
import FreelancePerks from '../components/FreelancePerks'
import Sectors from '../components/Sectors'
import Testimonials from '../components/Testimonials'
import Benefits from '../components/Benefits'
import FreelancePartnerships from '../components/FreelancePartnerships'

const FreelancePageTemplate = ({
  heroTitle,
  heroSubtitle,
  heroCta,
  heroImage,
  blueText,
  freelancePerks,
  freelancePartnerships,
  freelanceBenefits,
}) => {
  return (
    <>
      <Hero
        title={heroTitle}
        subTitle={heroSubtitle}
        cta={heroCta}
        ctaLink={`${process.env.GATSBY_APP_URL}/signup?pType=FREELANCE`}
        image={heroImage}
        className="freelance"
      />
      <ClientsPolicy text={blueText} />
      <FreelancePerks {...freelancePerks} />
      <Sectors />
      <FreelancePartnerships {...freelancePartnerships} />
      <Benefits
        benefitsTitle={freelanceBenefits.title}
        benefitsImage={freelanceBenefits.image}
        benefits={freelanceBenefits.benefits}
        descriptionLinkText={freelanceBenefits.descriptionLinkText}
        buttonURL={freelanceBenefits.buttonURL}
        buttonText={freelanceBenefits.buttonText}
      />
      <Testimonials />
      <div style={{ marginBottom: 100 }} />
    </>
  )
}

const FreelancePage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout isFree transparentHeader>
      <Helmet>
        <title>La communauté de freelances de l’ingénierie.</title>
        <meta name="description" content="Rejoignez notre communauté et bénéficiez d’accès exclusifs, via la plateforme, à des missions et avantages auprès de partenaires." />
        {/* Open Graph */}
        <meta name="og:title" content="Artelink - La communauté de freelances de l’ingénierie." />
        <meta name="og:image" content="" />
        <meta name="og:url" content="" />
        <meta name="og:description" content="Rejoignez notre communauté et bénéficiez d’accès exclusifs, via la plateforme, à des missions et avantages auprès de partenaires." />
      </Helmet>

      <FreelancePageTemplate {...frontmatter} />
    </Layout>
  )
}

FreelancePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default FreelancePage

export const pageQuery = graphql`
  query FreelancePageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "freelance-page" } }) {
      frontmatter {
        heroTitle
        heroSubtitle
        heroCta
        heroImage {
          childImageSharp {
            gatsbyImageData
          }
        }
        blueText
        freelancePerks {
          title
          perks {
            title
            text
            image {
              publicURL
            }
          }
        }
        freelancePartnerships {
          title
          text
          partnerships {
            type
            offer
            logo {
              publicURL
            }
          }
        }
        freelanceBenefits {
          title
          image {
            publicURL
          }
          benefits {
            icon {
              publicURL
            }
            text
          }
          descriptionLinkText
          buttonURL
          buttonText
        }
      }
    }
  }
`
