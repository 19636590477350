import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import ReactMarkdown from 'react-markdown'

import * as s from './style.module.sass'
import ContentWrapper from '../ContentWrapper'

const Testimonials = () => {
  const data = useStaticQuery(graphql`
    {
      markdownRemark(frontmatter: { componentKey: { eq: "testimonials" } }) {
        frontmatter {
          title
          subTitle
          link
          linkText
          testimonials {
            content
            name
            role
            company
            picture {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `)
  const { title, subTitle, testimonials } = data.markdownRemark.frontmatter

  return (
    <ContentWrapper className={s.wrapper}>
      <div className={s.leftColumn}>
        <h1 className={s.title}>
          <ReactMarkdown>{title}</ReactMarkdown>
        </h1>
        <div className={s.subTitle}>{subTitle}</div>
      </div>
      <div className={s.testimonies}>
        {testimonials.map(({ content, name, role, company, picture }, index) => (
          <div className={s.testimony} key={index}>
            <div className={s.apostrophes}>‘‘</div>
            <div className={s.contentWrapper}>
              <div className={s.textWrapper}>
                <div className={s.content}>{content}</div>
                <div className={s.name}>{name}</div>
                <div className={s.roleAndCompany}>
                  <div>{role}</div>
                  <div>{company}</div>
                </div>
              </div>
              <div className={s.imgWrapper}>
                <GatsbyImage image={picture?.childImageSharp?.gatsbyImageData} alt={name} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </ContentWrapper>
  )
}

export default Testimonials
